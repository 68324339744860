export const images = [
  null,
  './images/perspective.webp',
  './images/spacedInvaders.webp',
  'https://github.com/Chris-Hesterman/ch-images/blob/master/chImages/ProductPageProxyDemo.gif?raw=true',
  './images/SDC.webp',
  './images/dashboardChallenge.webp',
  './images/calculator.webp',
  // './images/weatherApp.webp',
  './images/dadJokes.webp'
];

export const descriptions = [
  '',
  'React  styled-components\n - created to experiment with css perspective\n - changes according to user input parameters\n - all changes smoothly animate as entered',
  'React Howler.js styled-components\n - aliens look & move like original arcade game\n - turret moves and fires\n - original game sounds\n - TODO: collision detection, various optimizations',
  'MongoDB  MySQL  Express  React  Node  AWS\n - team project utilizes service oriented architecture\n - each member created 2 full stack services & proxy\n - page accurately reproduces look, function of original\n - services & proxy deployed to EC2 instances',
  'PostgreSQL  Express  React  Redux  Node  AWS\n - each team member inherited 1 full stack service\n - goal was to overhaul/scale backend\n - local optimization, deployment to multiple EC2\n - various scaling tactics & load testing',
  'PostgreSQL  Express  React  Node  Docker\n - dashboard code challenge for language app\n - timed with goal of full stack MVP, basic CRUD\n - install, initialize, seed db, start with one command',
  'React  styled-components\n - MVP created to get acquainted with hooks\n - basic functionality, more to be added',
  // ' - User inputs new locations (very forgiving).\n - Used alternate api to browser geolocation.\n - JavaScript, HTML, CSS, openWeatherMap api',
  'React  react-flip-toolkit  icanhazdadjoke api\n - created when first learning React\n - rating joke affects list position, emoji, ring color'
];

export const siteUrls = [
  '',
  'https://frosty-jepsen-0f87b6.netlify.app',
  'https://mystifying-neumann-0accfe.netlify.app/',
  'https://github.com/KichiUeda',
  'https://github.com/Abee-Petco',
  'https://github.com/Chris-Hesterman/DashboardCodeChallenge',
  'https://competent-jones-bb9e02.netlify.app/',
  'https://chris-hesterman.github.io/Dad-Humor/'
];

export const titles = [
  '',
  '3d carousel visualizer (2021) - VISIT\n',
  'Nostalgic side project (2021) - VISIT\n',
  'FEC - Humble Bundle Clone (2020) - REPO\n',
  'SDC - Petco Clone (2020) - REPO\n',
  'MVP dashboard and quiz app (2021) - REPO\n',
  'MVP React calculator (2021) - VISIT\n',
  // 'Weather app (2017)\n',
  'Dad Jokes app (2017) - VISIT\n'
];

export const contact = {
  email: 'chrishesterman14@gmail.com',
  LinkedIn: 'https://www.linkedin.com/in/chris-hesterman/',
  Github: 'https://github.com/Chris-Hesterman'
};

export const facts = [
  {
    topic: 'Who am I?',
    bullet:
      "I'm a software engineer currently focused on Javascript and the MERN stack. I'm always open to new opportunities!"
  },
  {
    topic: 'Big deal, there are lots of them.',
    bullet: 'True, but I am the one you want to hire.'
  },
  {
    topic: 'Why?',
    bullet: 'Coming soon! '
  }
  // {
  //   topic: '',
  //   bullet: ''
  // }
];
