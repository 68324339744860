import styled from 'styled-components';
import {
  AiOutlineMail,
  AiOutlineLinkedin,
  AiOutlineGithub
} from 'react-icons/ai';

export const StyledContainer = styled.div`
  position: absolute;
  color: #00bbff;
  top: 1.5rem;
  left: calc(50% - 10rem);
  display: flex;
  justify-content: space-around;
  width: 20rem;
  z-index: 100;
  @media (max-width: 1100px) {
    top: 90%;
  }
  @media (max-width: 767px) {
    top: 85%;
  }
`;

export const StyledLink = styled.a`
  color: #00bbff;
`;

export const StyledEmail = styled(AiOutlineMail)`
  font-size: 3rem;
  &:hover {
    cursor: pointer;
    color: lightblue;
  }
  @media (max-width: 767px) {
    font-size: 3rem;
  }
`;

export const StyledLinkedin = styled(AiOutlineLinkedin)`
  font-size: 3rem;
  &:hover {
    cursor: pointer;
    color: lightblue;
  }
  @media (max-width: 767px) {
    font-size: 3rem;
  }
`;

export const StyledGithub = styled(AiOutlineGithub)`
  font-size: 3rem;
  &:hover {
    cursor: pointer;
    color: lightblue;
  }
  @media (max-width: 767px) {
    font-size: 3rem;
  }
`;
