import {
  StyledHeader,
  StyledWrapper,
  StyledH1,
  StyledH2,
  StyledDL,
  StyledList,
  StyledLi,
  StyledLink,
  StyledButton
} from './HeaderStyles';

const Header = () => {
  return (
    <StyledHeader>
      <StyledWrapper>
        <StyledH1>Chris Hesterman</StyledH1>
        <StyledH2>Software Engineer</StyledH2>
        {/* <StyledDL href="https://drive.google.com/uc?id=1z4nWGMwMRKU-gFbRpKL7S7RFDgqBClfB&export=download"> */}
        <StyledButton>
          <StyledDL href="https://drive.google.com/uc?id=1z4nWGMwMRKU-gFbRpKL7S7RFDgqBClfB&export=download">
            Resume
          </StyledDL>
        </StyledButton>
      </StyledWrapper>
      <StyledList>
        <StyledLi>
          <StyledLink to="/projects">PROJECTS</StyledLink>
        </StyledLi>
        <StyledLi>
          <StyledLink to="/home">ABOUT</StyledLink>
        </StyledLi>
      </StyledList>
    </StyledHeader>
  );
};

export default Header;
