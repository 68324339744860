import Carousel from '../Carousel/Carousel';
import AnimatedPage from '../Animated/AnimatedPage';
import { StyledContainer } from './ProjectsStyles';

const Projects = () => {
  return (
    <AnimatedPage>
      <StyledContainer>
        <Carousel width={19.5} height={18} zAxis={0} />
      </StyledContainer>
    </AnimatedPage>
  );
};

export default Projects;
