import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Projects from '../Projects/Projects';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Home from '../Home/Home';

const App = () => {
  const location = useLocation();

  return (
    <>
      <Header />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes key={location.pathname} location={location.pathname}>
          <Route path="/home" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/*" element={<Navigate to="/projects" />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
};

export default App;
