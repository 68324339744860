import { StyledFacet, StyledSpan } from './FacetStyles';
import TextFacet from './TextFacet';

const Facet = ({
  number,
  angle,
  apothem,
  width,
  height,
  description,
  url,
  title
}) => {
  return (
    <StyledFacet
      angle={angle}
      apothem={apothem}
      width={width}
      height={height}
      title="facet"
    >
      {number}
      {number > 1 ? (
        <pre>
          <StyledSpan>
            <a href={url} target="_blank" rel="noreferrer">
              {title}
            </a>
            {description}
          </StyledSpan>
        </pre>
      ) : (
        <TextFacet />
      )}
    </StyledFacet>
  );
};

export default Facet;
