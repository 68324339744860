import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-sizing: border-box;
  border-bottom: 1px solid #00bfff;
  height: calc(100vh - 1px);
  width: 100vw;
  overflow: hidden;
  perspective: 90rem;
  perspective-origin: 50% 95%;
  @media (max-width: 767px) {
    perspective-origin: 50% 85%;
  }
`;
