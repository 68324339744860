import { useRef, useEffect, useCallback } from 'react';
import { StyledCarousel } from './CarouselStyles';
import { images } from '../../constants';
import { generateFacets } from '../../utils';

const Carousel = ({ width, height, zAxis }) => {
  const time = useRef(Date.now());
  const carouselRef = useRef();
  const angle = 45;
  let backSides = useRef([3, 4, 5, 6, 7]);
  let rotationAngle = 0;
  let facets = [...Array(8 + 1).keys()];
  facets.shift();

  const handleClick = (e) => {
    const small = window.visualViewport.width < 768 ? true : false;
    const timeNow = Date.now();

    if (timeNow - time.current > 700 && e.target.tagName !== 'A') {
      rotationAngle += angle;
      time.current = timeNow;

      carouselRef.current.style.transform = `rotateY(${-rotationAngle}deg) scale3d(${
        small ? '0.8, 0.8, 0.8' : '1, 1, 1'
      })`;
      setTimeout(() => {
        changeFacetColor();
      }, 200);
    }
  };

  const changeFacetColor = () => {
    const last = backSides.current[backSides.current.length - 1];
    const newBackSide = last === facets.length ? 1 : last + 1;

    backSides.current.shift();
    backSides.current.push(newBackSide);

    setFacetColor(carouselRef, backSides);
  };

  const setFacetColor = useCallback(() => {
    carouselRef.current.childNodes.forEach((node, index) => {
      const isBehind = backSides.current.includes(+node.textContent[0]);
      const img = images[index % 8];

      node.childNodes[1].style.opacity = isBehind ? '0' : '1';
      node.style.background = isBehind
        ? 'rgba(50, 50, 50, .8)'
        : `url(${img}) no-repeat black`;
      node.style.color = isBehind ? 'transparent' : 'transparent';
      node.style.backgroundSize = '100% calc(100% - 6rem)';
    });
  }, [backSides]);

  useEffect(() => {
    setFacetColor();
  }, [backSides, setFacetColor]);

  return (
    <StyledCarousel
      rotationAngle={rotationAngle}
      angle={angle}
      onClick={handleClick}
      ref={carouselRef}
      zAxis={zAxis}
      title="carousel"
      data-cy="carousel"
    >
      {generateFacets(facets, angle, width, height)}
    </StyledCarousel>
  );
};

export default Carousel;
