import { StyledH2, StyledP } from './HomeStyles';

const ListItem = ({ topic, bullet }) => {
  return (
    <li>
      <StyledH2>{topic}</StyledH2>
      <StyledP>{bullet}</StyledP>
    </li>
  );
};

export default ListItem;
