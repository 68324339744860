import AnimatedPage from '../Animated/AnimatedPage';
import ListItem from './ListItem';
import { facts } from '../../constants.js';
import { StyledContainer, StyledUl } from './HomeStyles';

const Home = () => {
  const listItems = facts.map((fact) => {
    return (
      <ListItem key={fact.bullet} topic={fact.topic} bullet={fact.bullet} />
    );
  });

  return (
    <AnimatedPage>
      <StyledContainer>
        <StyledUl>{listItems}</StyledUl>
      </StyledContainer>
    </AnimatedPage>
  );
};

export default Home;
