import styled from 'styled-components';

export const StyledFacet = styled.div`
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  background: rgba(200, 200, 200, 0.5);
  border: 1px solid #00bfff;
  border-radius: 10px;
  height: ${(props) => `${props.height}rem`};
  width: ${(props) => `${props.width}rem`};

  &:hover {
    cursor: grab;
  }
  position: absolute;
  left: ${(props) => `${-props.width / 2}rem`};
  top: ${(props) => `${-props.height / 2}rem`};
  transition: all 0.25s ease-in-out;
  transform: ${(props) =>
    `rotateY(${props.angle}deg) translateZ(${
      props.apothem + 0.9
    }rem) scale(1.05)`};
  @media (max-width: 767px) {
    transform: ${(props) =>
      `rotateY(${props.angle}deg) translateZ(${props.apothem}rem) scale(1)`};
  }
`;

export const StyledSpan = styled.span`
  color: white;
  position: fixed;
  bottom: 2rem;
  left: 0;
  font-size: 0.75rem;
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  height: 4rem;
  padding-left: 1rem;
  & a {
    color: lightblue;
    font-weight: 700;
    &:focus {
      color: lime;
    }
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -1rem;
  width: 93%;
`;
export const StyledH = styled.h4`
  color: white;
  margin: 0 auto 0 auto;
`;

export const StyledBlurb = styled.p`
  color: white;
  font-size: 1.2rem;
  text-align: center;
`;
