import styled from 'styled-components';

export const StyledCarousel = styled.div`
  margin-top: 10vh;
  position: absolute;
  left: 50%;
  top: 55%;
  transform-style: preserve-3d;
  transform-origin: ${(props) => `50% 50% ${props.zAxis}rem`};
  transform-style: preserve-3d;
  transform: ${(props) =>
    `rotateY(0deg) translateZ(${props.zAxis}rem) scale3d(1, 1, 1)`};
  transition: all 0.5s ease-in-out;
  @media (max-width: 1024px) {
    top: 50%;
  }
  @media (orientation: portrait) {
    top: 50%;
  }
  @media (max-width: 767px) {
    margin-top: 0;
    transform: ${(props) => `rotateY(0deg) scale3d(0.8, 0.8, 0.8)`};
    top: 60%;
  }
`;
