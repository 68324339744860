import Facet from './components/Facet/Facet';
import { descriptions, siteUrls, titles } from './constants';

const apothem = 25.4;

export const generateFacets = (facets, angle, width, height) => {
  return facets.map((facet) => {
    const newAngle = angle * (facet - 1);

    return (
      <Facet
        key={facet}
        id={facet}
        number={facet}
        angle={newAngle}
        apothem={apothem}
        width={width}
        height={height}
        description={descriptions[(facet - 1) % descriptions.length]}
        url={siteUrls[(facet - 1) % siteUrls.length]}
        title={titles[(facet - 1) % titles.length]}
      />
    );
  });
};

export const resize = () => {
  window.addEventListener('resize', (e) => {
    let width = window.innerwidth < 1100 ? false : true;
    return width;
  });
};
