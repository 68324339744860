import { StyledDiv, StyledH, StyledBlurb } from './FacetStyles';

const TextFacet = () => {
  return (
    <StyledDiv>
      <StyledH>I'm</StyledH>
      <StyledH>Chris Hesterman</StyledH>
      <StyledBlurb>I made this thing.</StyledBlurb>
    </StyledDiv>
  );
};

export default TextFacet;
