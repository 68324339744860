import {
  StyledContainer,
  StyledLink,
  StyledEmail,
  StyledLinkedin,
  StyledGithub
} from './FooterStyles';

const Footer = () => {
  return (
    <StyledContainer>
      <StyledLink href="mailto: chrishesterman14@gmail.com" name="email me">
        <StyledEmail alt="email icon" />
      </StyledLink>
      <StyledLink
        href="https://www.linkedin.com/in/chris-hesterman/"
        target="_blank"
        name="linkedin profile"
      >
        <StyledLinkedin alt="linkedin icon" />
      </StyledLink>
      <StyledLink
        href="https://github.com/Chris-Hesterman"
        target="_blank"
        name="github profile link"
      >
        <StyledGithub alt="github icon" />
      </StyledLink>
    </StyledContainer>
  );
};

export default Footer;
