import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: calc(100vh - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #00bfff;
`;

export const StyledUl = styled.ul`
  width: 50vw;
  font-size: 1.2rem;
  padding-left: 0;
  @media (max-width: 967px) {
    width: 80vw;
  }
`;

export const StyledH2 = styled.h2`
  margin-bottom: 1rem;
  color: lightblue;
`;

export const StyledP = styled.p`
  margin-left: 2rem;
  margin-top: 0;
  font-size: 1.3rem;
`;
