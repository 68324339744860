import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledHeader = styled.nav`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 4rem 0 4rem;
  position: fixed;
  z-index: 20;
  width: 100vw;
  @media (max-width: 767px) {
    justify-content: space-between;
  }
  @media (max-width: 550px) {
    padding: 0 2rem 0 2rem;
  }
`;

export const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  color: lime;
  display: flex;
  width: 15rem;
  padding: 0;
  color: lime;
  @media (max-width: 1200px) {
    margin: 0;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const StyledLi = styled.li`
  margin: 2rem 0 0 0rem;
  @media (max-width: 550px) {
    margin-left: 0.7rem;
  }
`;

export const StyledLink = styled(NavLink)`
  text-align: left;
  margin-left: 0;
  font-size: 1.3rem;
  color: #00bfff;
  text-decoration: none;
  font-weight: 400;
  display: inline-block;
  transition: all 0.1s linear;
  &.active {
    color: lightblue;
    font-weight: 700;
    transform: scale(1.1);
  }
  /* @media (max-width: 1200px) {
    margin: 0 0 0 2rem;
  } */
  @media (max-width: 767px) {
    font-size: 1rem;
    margin-left: 1rem;
  }
`;

export const StyledWrapper = styled.div`
  text-align: left;
`;

export const StyledH1 = styled.h1`
  font-style: italic;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0;
  @media (max-width: 1200px) {
    margin-top: 0;
  }
  /* @media (max-width: 767px) {
    font-size: rem;
  } */
`;

export const StyledH2 = styled.h2`
  @media (max-width: 550px) {
    font-size: 1rem;
  }
`;

export const StyledDL = styled.a`
  font-size: 1.25rem;
  text-decoration: none;
  color: lightblue;
  transition: all 0.1s linear;
  &:active {
    transform: scale(1.05);
    color: #00bbff;
  }
`;

export const StyledButton = styled.button`
  background: transparent;
  height: 2rem;
  /* border-radius: 5px; */
  border: 1px solid lightblue;
  padding: 0 0.5rem 0 0.5rem;
  transition: all 0.1s linear;
  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: scale(1.05);
    border: 1px solid #00bbff;
  }
`;
